import { Link } from '@remix-run/react'
import SocialMediaLinks from './social-media-links'

type Props = {
  theme: JSX.Element
}

const Footer = (props: Props) => {
	return (
		<footer>
			<div className="bg-background max-h-full max-w-full">
				<section
					aria-label='social media links and dark/light button'
				>
					<div
						className="container flex justify-center pb-3 max-h-64"
						aria-label='social media links'
					>
						{/* max-h-x needs to be updated to add more than one row of icons */}
						<SocialMediaLinks />
						<div
							className="mt-8 ml-20"
							aria-label='dark/light mode control'
						>
							{props.theme /* for dark/light mode control */}
						</div>
					</div>
					<div className="container flex justify-center pb-5">
						<div className='px-2'>
						<Link
							to='/cookie-policy'
							className='link'
						>
							Cookie Policy
						</Link>
						</div>
						<div className='px-2'>
						<Link
							to='/newsletter-policy'
							className='link'
						>
							Newsletter Policy
						</Link>
						</div>
					</div>
					<div className="container flex justify-center pb-5">
						<div className="mb-4">
							<div 
								aria-labelledby='copyright 2024 Ramona Ridgewell. All rights reserved.'
								className='box-border'
							>
								&copy; 2024 Ramona Ridgewell. All rights reserved.
							</div>
						</div>
					</div>
				</section>
			</div>
		</footer>
	)
}
export default Footer
