import bluesky from './bluesky_media_kit_logo_svgs.svg'
import instagram from './ig-instagram-icon.svg'

export const logos = [
	{
		src: instagram,
		alt: 'Instagram',
		arialabel: 'link to Instagram @ramonaridgewellwrites',
		href: 'https://www.instagram.com/ramonaridgewellwrites/',
		column: 1,
		row: 1,
	},
	{
		src: bluesky,
		alt: 'Bluesky',
		arialabel: 'link to Bluesky @ramonaridgewell',
		href: 'https://bsky.app/profile/ramonaridgewell.bsky.social/',
		column: 1,
		row: 1,
	},
]
