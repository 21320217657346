import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '#app/components/ui/tooltip.tsx'
import { logos } from '#app/routes/_public+/logos/logos.js'
import { cn } from '#app/utils/misc.tsx'

// Tailwind Grid cell classes lookup
const columnClasses: Record<(typeof logos)[number]['column'], string> = {
	1: 'xl:col-start-1',
	2: 'xl:col-start-2',
	3: 'xl:col-start-3',
	4: 'xl:col-start-4',
	5: 'xl:col-start-5',
}
const rowClasses: Record<(typeof logos)[number]['row'], string> = {
	1: 'xl:row-start-1',
} //as we add more icons, necessitating more rows, we need to change xl:grid-rows-1 to a larger value to match the number of rows

const SocialMediaLinks: React.FC = () => {
  const getIconTable = () => {
    return (
      <div>
				<ul className="mt-8 flex max-w-lg flex-wrap gap-4">
					<TooltipProvider>
						{logos.map((logo, i) => (
							<li
								key={logo.href}
								className={cn(
									columnClasses[logo.column],
									rowClasses[logo.row],
									'animate-roll-reveal [animation-fill-mode:backwards]',
								)}
								style={{ animationDelay: `${i * 0.07}s` }}
							>
								<Tooltip>
									<TooltipTrigger asChild>
										<a
											aria-label={logo.arialabel}
											href={logo.href}
											className='
												grid size-20
												place-items-center
												rounded-2xl
												p-4
												transition
												hover:-rotate-6
												button-hover
												button-focus
												bg-accessibility-teal-green-500
												dark:bg-accessibility-teal-green-100
											'
										>
											<img 
												src={logo.src}
												alt={logo.alt}
											/>
										</a>
									</TooltipTrigger>
									<TooltipContent>{logo.alt}</TooltipContent>
								</Tooltip>
							</li>
						))}
					</TooltipProvider>
				</ul>
      </div>
    )
  }
  return (
    getIconTable()
  )
}

export default SocialMediaLinks
